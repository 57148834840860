import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import { urlHttpPrefix } from "../../../../../../js/helpers";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";

export default function CompanyMarker(props) {
	return (
		<Tippy
			theme="light"
			trigger="manual"
			visible={!!props.isOpen}
			interactive
			placement="top"
			arrow
			hideOnClick={false}
			content={
				<div>
					<h5>{props.company.name}</h5>
					{`${props.company.address_street} ${props.company.address_number}`}
					{props.company.address_addition}
					<br />
					{`${props.company.address_zipcode} ${props.company.address_city}`}
					{!!props.company.phone && (
						<>
							<br />
							Tel: {props.company.phone}
						</>
					)}
					<br />
					{!!props.company.email && (
						<>
							<a target="_parent" href={`mailto:${props.company.email}`} className="link" rel="noopener noreferrer">
								{props.company.email}
							</a>
							<br />
						</>
					)}
					{!!props.company.website && (
						<>
							<a target="_blank" href={urlHttpPrefix(props.company.website)} className="link" rel="noopener noreferrer">
								{props.company.website}
							</a>
							<br />
						</>
					)}
					{!!props.onStreetviewClick && (
						<a onClick={props.onStreetviewClick} className="link">
							Street View
						</a>
					)}
				</div>
			}>
			<div
				style={{
					color: "white",
					background: "#005e5d",
					width: "30px",
					height: "30px",
					display: "inline-flex",
					textAlign: "center",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "100%",
					transform: "translate(-50%, -50%)",
				}}
				onClick={() => {
					props.onClick(props.company);
				}}>
				<EvaIcon type="home" width="20" height="20" fill="white" />
			</div>
		</Tippy>
	);
}

CompanyMarker.propTypes = {
	onStreetviewClick: PropTypes.func,
	onClick: PropTypes.func,
	company: PropTypes.object,
	isOpen: PropTypes.bool,
};
