import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";

export default function CompanyMiniMapMarker() {
	return (
		<div
			style={{
				color: "white",
				background: "#005e5d",
				width: "30px",
				height: "30px",
				display: "inline-flex",
				textAlign: "center",
				alignItems: "center",
				justifyContent: "center",
				borderRadius: "100%",
				transform: "translate(-50%, -50%)",
			}}>
			<EvaIcon type="home" width="20" height="20" fill="white" />
		</div>
	);
}
