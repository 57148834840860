import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";
import EvaIcon from "../../../../../../js/react/components/general/EvaIcon";

export default function CurrentLocationMarker(props) {
	return (
		<Tippy theme="light" trigger="mouseenter" placement="top" arrow content={props.label}>
			<div
				style={{
					color: "white",
					background: "#ffb030",
					width: "30px",
					height: "30px",
					display: "inline-flex",
					textAlign: "center",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "100%",
					transform: "translate(-50%, -50%)",
				}}
				onClick={props.onClick}>
				<EvaIcon type="person" width="20" height="20" fill="white" />
			</div>
		</Tippy>
	);
}

CurrentLocationMarker.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
};
